import logo from '../../images/logo.png';
import logoDark from '../../images/logo-dark.png';
import arrowWhite from '../../images/arrow-white.png';
import arrowDark from '../../images/arrow-dark.png';
import iconLight from '../../images/icon-light.png';
import iconLighting from '../../images/icon-lighting.png';
import iconX from '../../images/icon-X.png';
import iconLinkedin from '../../images/icon-linkedin.png';
import iconSun from '../../images/icon-sun.png';
import iconMoon from '../../images/icon-moon.png';
import { useState, useEffect } from 'react';
import { clsx } from 'clsx'

const Home = () => {
    const [darkMode, setDarkMode] = useState(false);

    useEffect(() => {
        const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
        setDarkMode(isDarkMode);
    }, []);

    return (
        <div className={clsx(darkMode && 'dark')}>
            <div className="min-w-[375px] min-h-screen w-full flex flex-col bg-light dark:bg-dark bg-cover">
                <div className="header pt-4 pb-3 flex justify-between items-center xs:px-4 md:px-6 lg:px-16">
                    <img
                        src={darkMode ? logoDark : logo}
                        alt="logo"
                        className="xs:w-20 lg:w-28 h-auto"
                    />
                    <div className="flex justify-between items-center gap-6">
                        <a
                            className="flex px-4 justify-between items-center w-[120px] h-9 bg-orange rounded-full text-white text-[14px]"
                            href="mailto:support@cerebras.ai"
                        >
                            Contact Us 
                            <img src={arrowWhite} alt="arrow" className="w-4 h-4" />
                        </a>
                        <div
                            className="h-5 w-5 cursor-pointer"
                            onClick={() => {
                                setDarkMode(!darkMode);
                            }}
                        >
                            <img src={darkMode ? iconSun : iconMoon} alt="darkmode-toggle" />
                        </div>
                    </div>
                </div>

                <div className="body text-[#3B1B0C] flex-1 dark:text-white flex flex-col justify-center sm:pb-20">
                    <div className="text-center px-4 xs:mb-8 md:mb-28 lg:mb-16 xl:mb-20">
                        <p className="xs:text-4xl lg:text-4xl font-bold max-w-[650px] my-0 mx-auto mb-4">
                            Cerebras Developer Documentation
                        </p>
                        <p className="text-base max-w-[500px] my-0 mx-auto">
                            Find everything you need to get started with our products, including
                            technical guides, examples, and API references.
                        </p>
                    </div>
                    
                    <div className="flex gap-4 justify-center flex-wrap xs:pt-4 sm:pt-10">
                        <a
                            href="https://training-docs.cerebras.ai"
                            className="xs:w-[328px] sm:w-[296px] md:w-[352px] lg:w-[376px] xl:w-[560px] rounded-3xl border border-light p-8 shadow-md bg-card-light dark:bg-card-dark dark:border-dark"
                        >
                            <div className="flex justify-start items-center mb-4">
                                <img src={iconLight} alt="icon-light" className="w-6 h-6 mr-3" />
                                <span className="text-xl font-bold lg:text-2xl xl:text-2xl">
                                    Training
                                </span>
                                <img
                                    src={darkMode ? arrowWhite : arrowDark}
                                    alt="arrow"
                                    className="w-6 h-6 ml-auto"
                                />
                            </div>
                            <p className="text-sm dark:text-text-dark">
                                Effortlessly train and fine-tune large AI models with Cerebras
                                PyTorch and ModelZoo, powered by the world's largest AI-optimized
                                processor.
                            </p>
                        </a>
                        <a
                            href="https://inference-docs.cerebras.ai"
                            className="xs:w-[328px] sm:w-[296px] md:w-[352px] lg:w-[376px] xl:w-[560px] rounded-3xl border border-light p-8 shadow-md bg-card-light dark:bg-card-dark dark:border-dark"
                        >
                            <div className="flex justify-start items-center mb-4">
                                <img
                                    src={iconLighting}
                                    alt="icon-lighting"
                                    className="w-6 h-6 mr-3"
                                />
                                <span className="text-xl font-bold">Inference</span>
                                <img
                                    src={darkMode ? arrowWhite : arrowDark}
                                    alt="arrow"
                                    className="w-6 h-6 ml-auto"
                                />
                            </div>
                            <p className="text-sm dark:text-text-dark">
                                Build LLM applications with the world's fastest inference solution
                                using the Cerebras Inference SDK.
                            </p>
                        </a>
                    </div>
                </div>
                <div className="flex justify-between items-center py-4 xs:px-4 md:px-6 lg:px-16">
                    <div className="text-gray text-[14px] dark:text-light-gray">
                        © 2024 Cerebras. All rights reserved
                    </div>
                    <div className="flex justify-between items-center gap-5">
                        <a href="https://www.linkedin.com/company/cerebras-systems/">
                            <img src={iconLinkedin} alt="icon-linkedin" className="w-6 h-6" />
                        </a>
                        <a href="https://x.com/cerebrassystems">
                            <img src={iconX} alt="icon-X" className="w-6 h-6" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
